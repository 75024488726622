@import url(https://fonts.googleapis.com/css?family=Pacifico&subset=latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap);
@font-face {
  font-family: opensans;
  src: url("/fonts/open-sans/opensans-light-webfont.eot");
  src: url("/fonts/open-sans/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/open-sans/opensans-light-webfont.woff2") format("woff2"), url("/fonts/open-sans/opensans-light-webfont.woff") format("woff"), url("/fonts/open-sans/opensans-light-webfont.ttf") format("truetype"), url("/fonts/open-sans/opensans-light-webfont.svg#open_sansbold") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: opensans;
  src: url("/fonts/open-sans/opensans-regular-webfont.eot");
  src: url("/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("/fonts/open-sans/opensans-regular-webfont.woff") format("woff"), url("/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype"), url("/fonts/open-sans/opensans-regular-webfont.svg#open_sansbold") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: opensans;
  src: url("/fonts/open-sans/opensans-semibold-webfont.eot");
  src: url("/fonts/open-sans/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/open-sans/opensans-semibold-webfont.woff2") format("woff2"), url("/fonts/open-sans/opensans-semibold-webfont.woff") format("woff"), url("/fonts/open-sans/opensans-semibold-webfont.ttf") format("truetype"), url("/fonts/open-sans/opensans-semibold-webfont.svg#open_sansbold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: opensans;
  src: url("/fonts/open-sans/opensans-bold-webfont.eot");
  src: url("/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("/fonts/open-sans/opensans-bold-webfont.woff") format("woff"), url("/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype"), url("/fonts/open-sans/opensans-bold-webfont.svg#open_sansbold") format("svg");
  font-weight: 700;
  font-style: normal;
}

html {
  font-family: sans-serif;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  clear: both;
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button {
  cursor: pointer;
}

html input[type="button"] {
  cursor: pointer;
}

input[type="reset"] {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  box-sizing: content-box;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
}

body {
  color: #000;
  background-color: #fff;
  height: 100%;
  font-family: opensans;
  font-size: 15px;
}

* {
  box-sizing: border-box;
}

a {
  color: #dd406f;
  text-decoration: none;
}

a:hover {
  color: #dd406f;
  text-decoration: underline;
}

em {
  color: #dd406f;
  font-style: italic;
  font-weight: bold;
}

input {
  max-width: 100%;
}

.smart-underline {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 0 #fff, inset 0 -1px rgba(0, 0, 0, 0);
}

.smart-underline::selection {
  color: #fff;
  text-shadow: none;
  background: #e0537d;
}

.smart-underline:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 0 #fff, inset 0 -1px #dd406f;
}

.smart-underline-header {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 0 #fff, inset 0 -2px rgba(0, 0, 0, 0);
}

.smart-underline-header::selection {
  color: #fff;
  text-shadow: none;
  background: #e0537d;
}

.smart-underline-header:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 0 #fff, inset 0 -2px #dd406f;
}

.small-top-padding {
  padding-top: 7px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.fw-semibold {
  font-weight: 600;
}

.align-center {
  text-align: center;
}

.flex-space-between {
  justify-content: space-between;
  display: flex;
}

.position-relative {
  position: relative;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

body {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

h1 {
  color: #dd406f;
  margin: 20px 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
}

.style-2021 :not(a), .style-2021 h1 {
  color: #000;
}

.style-2021 table th {
  font-family: Caveat;
  font-size: 24px;
}

.style-2021 table th strong {
  color: #ee7598;
}

p {
  margin: 20px 0;
}

@media screen and (max-width: 800px) {
  body {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
  }

  h1 {
    color: #dd406f;
    margin: 0 0 12px;
    font-size: 30px;
    font-weight: 700;
    line-height: 27px;
  }

  .style-2021 h1 {
    color: #000;
  }

  p {
    margin: 12px 0;
  }
}

.ugo_banner {
  display: none;
}

.clear {
  clear: both;
}

.pt-20 {
  padding-top: 20px;
}

.none {
  display: none !important;
}

.container, footer {
  text-align: left;
  width: 1080px;
  margin: 0 auto;
  position: relative;
}

.container {
  width: 1050px;
}

.container .part-left, .container .part-right {
  padding-top: 20px;
  display: inline;
  overflow: hidden;
}

.container .part-left {
  float: left;
  width: 690px;
}

.container .part-right {
  float: right;
  width: 330px;
}

.container:after {
  content: "";
  clear: both;
  width: 100%;
  height: 1px;
  margin: -1px;
  display: table;
  overflow: hidden;
}

.section {
  background-color: #f5f7f9;
  margin-top: 5px;
}

.section.shadow {
  box-shadow: inset 0 15px 10px -10px #adadad;
}

.section.shadow + .section.shadow {
  box-shadow: none;
  margin-top: 0;
}

.section.shadow + .section.shadow .container {
  margin-top: 0;
}

.wrapper {
  background-color: #fff;
}

.warningbar {
  z-index: 999;
  color: #000;
  text-align: left;
  background-color: #f5f7f9;
  border-radius: 2px;
  min-width: 400px;
  max-width: 100%;
  padding: 25px;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, .2);
}

.warningbar .main-button {
  margin: 25px auto 0;
}

.registration h1 {
  color: #000;
  text-transform: uppercase;
  text-align: center;
  margin: 55px 0 0;
  font-size: 27px;
  line-height: 38px;
}

.registration hr {
  border-color: #ee7598;
}

.registration .steps {
  justify-content: space-between;
  align-items: center;
  width: 420px;
  margin: 15px auto 50px;
  display: flex;
}

.registration .steps__step {
  border: 1px solid #b1b1b1;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  position: relative;
}

.registration .steps__step.active {
  border-color: #ee7598;
}

.registration .steps__step:not(:last-of-type):after {
  content: "";
  background: #b1b1b1;
  width: 80px;
  height: 1px;
  position: absolute;
  top: 14px;
  left: 40px;
}

.registration .step__number {
  color: #b1b1b1;
  font-size: 15px;
  position: absolute;
  top: 4px;
  left: 10px;
}

.registration .step__number.active {
  color: #ee7598;
}

.registration .switch {
  width: 388px;
  margin: 25px auto 50px;
  display: flex;
}

.registration .switch a {
  white-space: nowrap;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #000;
  flex: 1;
  margin-right: -1px;
  padding: 18px 17px 17px;
  font-family: Caveat;
  font-weight: 700;
  line-height: 20px;
}

.registration .switch a:hover {
  color: #fff;
  background-color: #c22252;
  border: 1px solid #c22252;
  text-decoration: none;
}

.registration .switch a.active {
  color: #fff;
  background-color: #ee7598;
  text-decoration: none;
}

.registration .logins {
  align-items: center;
  width: 480px;
  margin: 0 auto 50px;
  display: flex;
}

.registration .logins label {
  margin-left: 50px;
  padding: 10px 0;
  line-height: 20px;
  display: inline-block;
}

.registration .logins a {
  color: #000;
  padding-left: 25px;
  text-decoration: none;
  position: relative;
}

.registration .logins a:before {
  content: "";
  border: 1px solid #adadad;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 2px;
  left: 0;
}

.registration .logins a.active:after {
  content: "";
  background-color: #dd406f;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 5px;
  left: 3px;
}

.registration .logins a:hover {
  text-decoration: none;
}

.registration .heart {
  background-image: url("/images/maminka-roku/dist/heart.png");
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  margin-bottom: 50px;
  display: block;
}

.rotate-loading {
  z-index: 999;
  text-align: center;
  background-color: rgba(150, 150, 150, .8);
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loading {
  border: 16px solid #ebeff3;
  border-top-color: #000;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 40% auto;
  animation: 2s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

form.main-form {
  border: 0;
  margin: 0;
  padding: 0;
}

form.main-form.registering {
  width: 480px;
  margin: 0 auto 200px;
}

form.main-form .field label {
  margin-left: 5px;
  font-size: 15px;
  line-height: 20px;
  display: block;
}

form.main-form .field label[for="profile_description"], form.main-form .field label[for="infodesc"] {
  margin-left: 0;
}

form.main-form .field input {
  border: 1px solid #adadad;
  border-radius: 8px;
  outline: none;
  width: 480px;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

form.main-form .field input:focus {
  box-shadow: 2px 4px 6px rgba(0, 0, 0, .2);
}

form.main-form .field textarea {
  outline: none;
  width: 480px;
  height: 350px;
  padding: 16px;
  font-size: 15px;
  line-height: 20px;
  overflow: scroll;
}

form.main-form .field textarea.error {
  border: 1px solid #e91d1d;
}

form.main-form .field textarea:focus {
  box-shadow: 0 4px 8px #ebeff3;
}

form.main-form .field select {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #adadad;
  border-radius: 8px;
  outline: none;
  width: 480px;
  padding: 10px;
}

form.main-form .field select:after {
  content: "";
}

form.main-form .field select option {
  padding: 10px;
  line-height: 20px;
}

form.main-form .field select.error {
  border: 1px solid #e91d1d;
}

form.main-form .field select:focus {
  box-shadow: 0 4px 8px #ebeff3;
}

form.main-form .field > span {
  margin-bottom: 25px;
  display: inline-block;
  position: relative;
}

form.main-form .field > span .icon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

form.main-form .field > span .icon.dropdown {
  border-top: 8px solid #000;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  display: block;
  top: 17px;
}

form.main-form .field > span .icon svg {
  width: 20px;
  height: 20px;
}

form.main-form .field > span.error {
  border: 1px solid #e91d1d;
  border-radius: 8px;
  position: relative;
}

form.main-form .field > span.error b.icon {
  display: block;
}

form.main-form .field > span.error input, form.main-form .field > span.error select, form.main-form .field > span.error textarea {
  border: 0;
}

form.main-form .field > span.error .captcha-field {
  border: 1px solid #adadad;
}

form.main-form .field > span.error.sel .icon.dropdown {
  border-top: 8px solid #e91d1d;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
}

form.main-form .field > span.error.sel b.icon, form.main-form .field > span.error.sel em.icon {
  display: none;
}

form.main-form .field > span.ok em.icon {
  display: block;
}

form.main-form .field > span.ok .icon.dropdown {
  display: none;
}

form.main-form .field > span.textarea {
  margin-bottom: 0;
}

form.main-form .field > span.textarea.error {
  border-radius: 0;
}

form.main-form .field > span .captcha-img {
  float: left;
  border: 1px solid #adadad;
  border-radius: 8px;
  height: 42px;
  margin-right: 10px;
  padding: 5px;
}

form.main-form .field > span .captcha-reload {
  float: left;
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 10px;
  padding: 10px;
  font-size: 25px;
  font-weight: bold;
  line-height: 20px;
}

form.main-form .field > span .captcha-reload:disabled {
  color: #adadad;
}

form.main-form .field > span .captcha-field {
  width: 112px;
}

form.main-form .field.upload {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}

form.main-form .field.upload label {
  margin: 0 30px 0 0;
  font-weight: 600;
}

form.main-form .field.upload input {
  border: 1px solid #adadad;
  width: auto;
  padding: 0 5px;
  font-size: 14px;
}

form.main-form .field.upload > span {
  margin: 0;
}

form.main-form .field.upload > span .icon {
  top: 1px;
  left: 120px;
  right: auto;
}

form.main-form .agreement {
  position: relative;
}

form.main-form .agreement a {
  color: #000;
  font-weight: 700;
}

form.main-form .agreement span {
  text-align: left;
  display: inline-block;
}

form.main-form .agreement .error {
  border: 1px solid #e91d1d;
  border-radius: 8px;
  margin: -2px;
  padding: 1px;
}

form.main-form .agreement__button {
  position: absolute;
  top: 150px;
  left: 400px;
}

form.main-form .info {
  color: #8295b1;
  font-size: 13px;
}

form.main-form .counter {
  text-align: right;
  color: #8295b1;
  width: 480px;
  margin-bottom: 25px;
  font-size: 13px;
}

.formsection {
  text-transform: uppercase;
  color: #ee7598;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.formsection:after {
  content: "";
  background-color: #ee7598;
  flex: 1;
  height: 1px;
}

.formsection strong {
  padding-right: 15px;
  display: inline-block;
}

.formsection__text {
  padding-right: 10px;
  font-weight: 600;
}

a.button, .button {
  text-align: center;
  border: 0;
  border-radius: 15px;
  padding: 4px 18px 6px;
  font-family: Pacifico;
  font-size: 25px;
  font-weight: 400;
  line-height: 44px;
  text-decoration: none;
  transition: background-color .5s;
  display: inline-block;
  position: relative;
}

.button:disabled {
  color: #f5f7f9;
  background: #ebeff3;
}

.button:focus {
  outline: 0;
}

.button > .icon {
  background-position: center;
  width: 100%;
  height: 15px;
  display: inline-block;
}

.icon.button {
  width: 40px;
}

a.button, .button {
  color: #fff;
  background: #dd406f;
}

a.button:hover:enabled, .button:hover:enabled {
  color: #fff;
  background: #c22252;
  text-decoration: none;
}

a.button.clicked:hover:enabled, .button.clicked:hover:enabled {
  background: #c22252;
}

a.button-alt, .button-alt {
  color: #000;
  border: 1px solid #adadad;
  border-radius: 15px;
  margin: 25px 0;
  padding: 17px 16px 18px;
  font-weight: 700;
  line-height: 20px;
  display: inline-block;
}

a.button-alt.withicon, .button-alt.withicon {
  padding-right: 57px;
  position: relative;
}

a.button-alt.withicon .icon, .button-alt.withicon .icon {
  width: 22px;
  height: 26px;
  position: absolute;
  top: 14px;
  right: 16px;
}

a.button-alt.withicon .icon svg, .button-alt.withicon .icon svg {
  fill: #000;
  width: 100%;
  height: auto;
}

a.button-alt:hover, .button-alt:hover {
  border: 1px solid #8295b1;
  text-decoration: none;
}

a.button-2021, .button-2021 {
  color: #000;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #000;
  padding: 10px 30px;
  font-family: Caveat;
  font-size: 24px;
  font-weight: 700;
  line-height: 42px;
  position: relative;
}

a.button-2021:before, .button-2021:before {
  content: "";
  background-color: rgba(238, 117, 152, .4);
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: absolute;
  top: 3px;
  left: 3px;
}

a.button-2021:hover, .button-2021:hover {
  text-decoration: none;
}

a.button-2021:hover:before, .button-2021:hover:before {
  background: #e84170;
}

a.button-2021.disabled:before, .button-2021.disabled:before {
  background: #f296b0;
}

input[type="submit"].button-2021 {
  background-color: rgba(238, 117, 152, .4);
}

.container header {
  border-bottom: 1px solid #adadad;
  height: 145px;
}

.container header .lines-button {
  display: none;
}

.container header .logo {
  color: rgba(0, 0, 0, 0);
  text-indent: -8000px;
  float: left;
  width: 175px;
  height: 69px;
  margin: 56px 30px 20px 0;
  display: inline-block;
}

.container header .maminka-logo-2019 {
  background-image: url("/images/maminka-roku/dist/maminka-logo-2019.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.container header nav > ul {
  float: left;
  text-transform: uppercase;
  z-index: 100;
  margin: 0;
  padding: 0;
  font-family: Caveat;
  font-weight: 700;
  list-style-type: none;
}

.container header nav > ul li {
  margin: 65px 0 0;
  font-size: 19px;
  display: inline-block;
}

.container header nav > ul li.closed ul {
  display: none;
}

.container header nav > ul li.menu-dropdown {
  display: inline-block;
  position: relative;
}

.container header nav > ul li.menu-dropdown ul {
  z-index: 100;
  background-color: #fff;
  border-top: 2px solid #41403e;
  border-radius: 255px 50px 0 0 / 7px 5px 0 255px;
  width: 100%;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
}

.container header nav > ul li.menu-dropdown ul li {
  text-align: center;
  width: 100%;
  margin: 0;
  line-height: 19px;
  display: block;
}

.container header nav > ul li.menu-dropdown ul li a {
  padding: 10px 0 5px;
  display: block;
}

.container header nav > ul li a, .container header nav > ul li span {
  color: #000;
  padding: 15px;
  display: inline-block;
}

.container header nav > ul li a:hover, .container header nav > ul li a.selected {
  color: #ee7598;
  text-decoration: none;
}

.container header nav > ul li .menu-logo-maminka {
  filter: brightness(0);
  background-size: cover;
  width: 150px;
  height: 22px;
  margin: 0 15px;
  padding: 0;
  display: inline-block;
}

.container header .maminka-logo-2020 {
  background-position: center;
  background-size: 100%;
}

.container header .maminka-logo-2021 {
  text-indent: 0;
}

.container header .icons-instagram {
  width: 40px;
  margin: 65px 0 0 20px;
  display: inline-block;
}

.container header .icons-instagram img {
  width: 100%;
}

.container header .icons-fb {
  width: 40px;
  margin: 65px 0 0 5px;
  display: inline-block;
}

.container header .icons-fb img {
  width: 100%;
}

.slider {
  border-top: 1px solid #adadad;
  padding: 15px 0;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  touch-action: pan-y;
  max-height: 150px;
  padding: 0 72px;
  display: block;
  position: relative;
}

.slick-slider figure {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 15px 30px;
}

.slick-slider figure span {
  width: 120px;
  height: 120px;
  margin: 0;
  display: block;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  image-rendering: crisp-edges;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid rgba(0, 0, 0, 0);
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev, .slick-next {
  color: rgba(0, 0, 0, 0);
  z-index: 1;
  background: #fff;
  border: 0;
  width: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
}

.slick-prev:focus, .slick-next:focus {
  outline: none;
}

.slick-prev:after, .slick-next:after {
  content: "";
  width: 20px;
  height: 20px;
  margin-top: -18px;
  display: block;
  transform: rotate(45deg);
}

.slick-prev {
  padding-left: 0;
  left: 6px;
}

.slick-prev:after {
  float: left;
  border-bottom: 7px solid #000;
  border-left: 7px solid #000;
}

.slick-next {
  padding-right: 0;
  right: 6px;
}

.slick-next:after {
  float: right;
  border-top: 7px solid #000;
  border-right: 7px solid #000;
}

.slider-ol {
  color: #fff;
  text-align: center;
  pointer-events: none;
  width: 100%;
  position: absolute;
  top: 0;
}

.slider-ol .slider-ol-inner {
  margin: 0 auto;
}

.slider-ol .slider-ol-inner > div {
  padding-top: 1vw;
  font-size: 2.5vw;
  font-weight: 600;
  line-height: 1.8em;
}

@media screen and (max-width: 800px) {
  .slider-ol .slider-ol-inner h1 {
    background-size: 41vw;
    width: 41vw;
    height: 24.5vw;
    margin-top: 8vw;
  }

  .slider-ol .slider-ol-inner > div {
    font-size: 4vw;
  }
}

.main-slider .slick-slide img {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .main-slider {
    margin: 0 -30%;
  }

  .main-slider .slick-prev, .main-slider .slick-next {
    width: 50px;
  }

  .main-slider .slick-prev:focus, .main-slider .slick-next:focus {
    outline: none;
  }

  .main-slider .slick-prev:after, .main-slider .slick-next:after {
    border-width: 3px;
    width: 25px;
    height: 25px;
  }

  .main-slider .slick-prev {
    padding-left: 10px;
    left: 20%;
  }

  .main-slider .slick-next {
    padding-right: 10px;
    right: 20%;
  }
}

.edition {
  z-index: 10;
  position: absolute;
  top: 20px;
  right: 15px;
}

.edition img {
  width: 112px;
  height: auto;
  transform: rotate(3deg);
}

.promo {
  background-image: url("/images/maminka-roku/dist/foto-maminky.jpg");
  background-position: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 389px;
  margin: 25px 25px 60px;
}

.promo > div {
  float: right;
  width: 430px;
  margin-top: 80px;
}

.promo > div ul {
  padding: 0;
  list-style: none;
}

.promo > div ul li {
  padding-left: 25px;
  position: relative;
}

.promo > div ul li:before {
  content: "";
  background-color: #dd406f;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}

.promo > div .button {
  margin-top: 50px;
}

.promo .badge {
  width: 255px;
  height: 250px;
  display: block;
  position: absolute;
  bottom: calc(50% - 108px);
  left: calc(50% - 333px);
}

.promoimg {
  z-index: 10;
  pointer-events: none;
  width: 100%;
  margin-top: -130px;
  position: relative;
}

.dynamic-article {
  padding: 0 10px;
}

.dynamic-article h1 {
  text-align: center;
  margin-top: -30px;
  font-size: 58px;
  line-height: 60px;
}

.dynamic-article ul {
  width: 200px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.dynamic-article ul li {
  text-indent: -.7em;
  padding-left: 1em;
}

.dynamic-article ul li:before {
  content: "";
  background-color: #dd406f;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  margin-right: 10px;
  display: inline-block;
}

.dynamic-article .promo-hp {
  margin-top: 25px;
}

.dynamic-article .promo-hp__top-wrapper {
  position: relative;
}

.dynamic-article .promo-hp__top-wrapper:after, .dynamic-article .promo-hp__top-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.dynamic-article .promo-hp__image-wrapper {
  float: left;
  max-width: 540px;
  height: auto;
  margin-bottom: 20px;
  margin-right: 10px;
  padding: 0 20px 0 0;
}

.dynamic-article .promo-hp__image-wrapper img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .dynamic-article .promo-hp__image-wrapper {
    float: none;
    max-width: 100%;
    margin-bottom: 8px;
    margin-right: 0;
    padding: 0;
  }

  .dynamic-article .promo-hp__perex {
    margin-top: 20px;
  }
}

.dynamic-article .promo-hp__perex .button-wrapper {
  margin: 0;
}

@media (max-width: 768px) {
  .dynamic-article .promo-hp__perex .button-wrapper {
    margin: 20px 0;
  }
}

.dynamic-article .promo-hp__perex--highlight {
  color: #dd406f;
  font-style: normal;
  font-weight: 400;
}

.dynamic-article .promo-hp__end-badge {
  z-index: 2;
  width: 208px;
  position: absolute;
  top: 170px;
  right: 0;
}

@media (max-width: 768px) {
  .dynamic-article .promo-hp__end-badge {
    width: 200px;
    top: calc(55vw + 260px);
    right: 50%;
    transform: translateX(100px);
  }
}

.dynamic-article .promo-hp__title {
  text-align: left;
  color: #dd406f;
  margin-top: 0;
  font-size: 30px;
  line-height: 30px;
}

.dynamic-article .promo-hp__text-color {
  color: #dd406f;
}

.dynamic-article .promo-hp__categories {
  flex-wrap: wrap;
  display: flex;
}

.dynamic-article .promo-hp__category-title {
  flex-basis: 100%;
  font-family: Caveat;
}

.dynamic-article .promo-hp__category {
  flex: 1 0 0;
  padding-right: 20px;
}

.dynamic-article .promo-hp__category:last-child {
  padding-right: 0;
}

.dynamic-article .promo-hp__category.promo-hp__category-2023 {
  width: 30%;
}

@media (max-width: 768px) {
  .dynamic-article .promo-hp__category {
    border-bottom: 1px solid #adadad;
    flex: 1 0 100%;
    padding-right: 0;
  }
}

.dynamic-article .promo-hp__patrons {
  margin: 0 0 20px;
}

.dynamic-article .patrons__text {
  text-align: center;
  margin: 20px 0 0;
  font-weight: 700;
  line-height: 25px;
}

.dynamic-article .patrons__images {
  justify-content: center;
  display: flex;
}

.dynamic-article .patrons__images img {
  border: 10px solid #fff;
  width: 230px;
  margin: 40px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .24);
}

.style-2021 table th {
  margin: 30px 0 20px;
  display: block;
}

.contest__profile-list-tabheader {
  position: relative;
}

.badge-semifinal-2023 {
  z-index: 2;
  width: 100%;
  position: absolute;
  top: 400px;
  left: 0;
}

.badge-semifinal-2023 img {
  width: 100%;
}

.badge-semifinal-2023.badge-semifinal-mobile {
  top: 380px;
}

.badge-finish-2023 {
  z-index: 2;
  width: 500px;
  height: 500px;
  margin: 0 auto;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
}

.badge-finish-2023 img {
  width: 100%;
}

.badge-finish-2023.badge-finish-mobile {
  height: 500px;
  top: -70px;
}

.detail {
  text-align: center;
  position: relative;
}

.detail .photo-detail {
  text-align: center;
  margin: 0 auto;
}

.detail .photo-detail .image {
  width: 510px;
  height: auto;
  margin: 15px auto;
}

.detail .photo-detail .image img {
  border-radius: 15px;
  width: 100%;
  height: auto;
}

.detail .prev, .detail .next {
  color: #000;
  border: 1px solid #adadad;
  border-radius: 15px;
  padding: 0 16px;
  font-size: 15px;
  font-weight: 700;
  line-height: 55px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 350px;
}

.detail .prev:after, .detail .next:after {
  content: "";
  border-color: #000;
  width: 10px;
  height: 10px;
  margin-top: 0;
  display: block;
  transform: rotate(45deg);
}

.detail .prev:hover, .detail .next:hover {
  color: #fff;
  background-color: #000;
  text-decoration: none;
}

.detail .next {
  right: 0;
}

.detail .next:after {
  float: right;
  border-top: 4px solid;
  border-right: 4px solid;
  margin: 20px 0 0 5px;
}

.detail .prev {
  left: 0;
}

.detail .prev:after {
  float: left;
  border-bottom: 4px solid;
  border-left: 4px solid;
  margin: 20px 5px 0 0;
}

.detail .profile {
  text-align: left;
  width: 510px;
  margin: 0 auto;
}

.detail .profile h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 30px;
}

.detail .profile.fullwidth {
  width: 100%;
}

.detail .profile .profile-info {
  word-break: break-word;
}

.detail .profile-stats {
  vertical-align: middle;
  border-bottom: 1px solid #adadad;
  margin-bottom: 20px;
  padding: 0 0 15px;
}

.detail .profile-stats .icon {
  float: left;
  height: 44px;
}

.detail .profile-stats .icon svg {
  fill: #dd406f;
  width: 40px;
  height: 44px;
  display: inline-block;
}

.detail .profile-stats > span {
  float: left;
  margin: 0 25px 0 10px;
  font-size: 15px;
  font-weight: 700;
  line-height: 50px;
}

.detail .profile-stats:after {
  content: "";
  clear: both;
  height: 1px;
  margin-top: -1px;
  display: table;
}

.detail .fblike {
  margin-top: 10px;
}

.detail #login-wrapper {
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
}

.crop-thumb {
  padding-top: 30px;
}

.crop-thumb .detail {
  display: none;
}

.crop-detail {
  width: 465px;
  margin: 0 auto;
}

.profile-info {
  text-align: left;
  width: 510px;
  margin: 0 auto;
  line-height: 20px;
}

.profile-info h2 {
  color: #dd406f;
  font-size: 18px;
}

.profile-info.text {
  column-count: 2;
  column-gap: 30px;
}

.profile-info.fullwidth {
  width: 100%;
}

video {
  margin: 25px 0;
}

.nextstep {
  text-align: right;
  padding-bottom: 15px;
}

.snackbar {
  visibility: hidden;
  color: #fff;
  text-align: center;
  z-index: 999;
  background-color: #dd406f;
  border-radius: 2px;
  min-width: 400px;
  margin-left: -200px;
  padding: 16px;
  position: fixed;
  bottom: 150px;
  left: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .2), 0 6px 20px rgba(0, 0, 0, .19);
}

.snackbar.show {
  visibility: visible;
  animation: .5s fadein, .5s 4s fadeout;
}

.captcha-fields .button {
  float: right;
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  width: 32%;
}

.captcha-fields .input-captcha {
  float: right;
  border: 1px solid #adadad;
  border-radius: 15px;
  width: 31%;
  margin-right: 3%;
  padding: 0 16px;
  overflow: hidden;
}

.captcha-fields .input-captcha.error {
  border: 1px solid #dd406f;
}

.captcha-fields .input-captcha input {
  -webkit-appearance: none;
  appearance: none;
  letter-spacing: 10px;
  text-align: center;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  width: 100%;
  padding: 0;
  font-size: 25px;
  line-height: 52px;
}

.captcha-fields .image-captcha {
  float: left;
  text-align: center;
  vertical-align: middle;
  background-color: #ebeff3;
  border: 1px solid #adadad;
  border-radius: 15px;
  width: 31%;
  height: 54px;
  padding: 11px 0;
}

.captcha-fields .image-captcha img {
  border-radius: 4px;
}

.text-captcha {
  text-align: center;
  border-bottom: 1px solid #adadad;
  margin-top: 15px;
  padding-bottom: 15px;
  font-weight: 600;
}

.text-captcha .error {
  color: #dd406f;
}

.vote--logged-in {
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
}

.vote--logged-in .button {
  width: 100%;
  padding: 0 18px;
}

.vote--logged-in .button svg {
  height: 54px;
}

.vote--logged-in .button .vote-text {
  padding: 4px 0 6px;
  display: inline-block;
}

.vote--logged-in .button .vote-loading {
  width: 97px;
  display: inline-block;
}

@keyframes fadein {
  from {
    opacity: 0;
    bottom: 100px;
  }

  to {
    opacity: 1;
    bottom: 150px;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    bottom: 150px;
  }

  to {
    opacity: 0;
    bottom: 100px;
  }
}

.profile__basics {
  width: 100%;
}

.profile__basics h1 {
  text-align: center;
  color: #000;
  text-transform: uppercase;
  margin: 30px 0 20px;
  font-family: Caveat;
  font-size: 23px;
  font-weight: 700;
}

.profile-basics__image {
  aspect-ratio: 190 / 150;
  width: 100%;
  margin: 0 0 30px;
  overflow: hidden;
}

.profile-basics__image img {
  border-radius: 0;
  width: 100%;
  height: auto;
}

.profile-basics__text {
  color: #ee7598;
  text-align: center;
  font-weight: 600;
}

.profile-basics__text p {
  margin: 0 0 50px;
}

.profile-basics__details {
  margin-bottom: 30px;
}

.profile-basics__details a {
  color: #000;
}

.profile-basics__details a:hover {
  color: #ee7598;
  text-decoration: none;
}

.profile-basics__login, .profile-basics__vote {
  justify-content: center;
  margin-bottom: 50px;
  display: flex;
}

.profile-basics__vote .button-2021 {
  width: 100%;
  padding: 0 34px;
  line-height: 36px;
}

.profile-basics__vote .button-2021 svg {
  height: 46px;
}

.profile-basics__vote .button-2021 .vote-text {
  padding: 4px 0 6px;
  display: inline-block;
}

.profile-basics__vote .button-2021 .vote-loading {
  width: 93px;
  display: inline-block;
}

.profile__info {
  width: 100%;
  margin-bottom: 50px;
  line-height: 21px;
}

.profile-info__text {
  column-count: 2;
  grid-column-gap: 40px;
}

.profile-info-comments__title {
  font-size: 18px;
  font-weight: 700;
}

.profile__divider {
  border-top: 2px solid #23292a;
  border-radius: 50% 10% 0 0 / 6% 5% 0 50%;
  height: 30px;
  margin: 0;
}

.profile__divider--short {
  width: 440px;
  margin: 0 0 20px;
}

.list-article {
  background-color: #fff;
  width: 690px;
  margin: 20px 0 40px;
  position: relative;
}

.list-article .image-container {
  background-position: center;
  background-size: cover;
  width: 330px;
  height: 165px;
  display: block;
}

.list-article .title-container {
  float: right;
  width: 360px;
  padding: 10px 30px;
}

.list-article .title-container h2 {
  font-size: 19px;
  line-height: 26px;
}

.list-article .title-container h2 a {
  color: #000;
}

.list-article .title-container h2 a:hover {
  color: #dd406f;
}

.list-article .meta {
  color: #dd406f;
  font-size: 13px;
  position: absolute;
  bottom: 10px;
}

.list-small {
  margin: 0;
  padding: 15px 0;
}

.list-small h2 {
  color: #dd406f;
  margin: 0 0 15px;
  font-size: 19px;
  line-height: 26px;
}

.list-small .list-article-small {
  float: left;
  background: #f5f7f9;
  width: 360px;
  height: 80px;
  margin: 0 0 35px;
}

.list-small .list-article-small .image-container {
  background-color: #fff;
  width: 150px;
  height: 80px;
}

.list-small .list-article-small .title-container {
  float: right;
  width: 210px;
  padding: 0 30px 0 15px;
}

.list-small .list-article-small .title-container h2 {
  font-size: 15px;
  line-height: 20px;
}

.list-small .list-article-small:nth-of-type(3n) {
  width: 330px;
}

.list-small .list-article-small:nth-of-type(3n) .title-container {
  width: 180px;
  padding: 0 0 0 15px;
}

.contest-nav {
  background-color: #ebeff3;
  border-radius: 15px;
  margin: 40px 0;
  padding: 13px 0;
  font-size: 18px;
  line-height: 24px;
  display: block;
}

.contest-nav .contest-search {
  float: right;
  background-color: #fff;
  background-position: 216px;
  background-size: 18px;
  border: 1px solid #adadad;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 255px;
  height: 50px;
  margin-top: -13px;
  margin-left: 15px;
}

.contest-nav .contest-search input {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  width: 200px;
  height: 48px;
  padding-left: 15px;
  font-size: 13px;
  line-height: 48px;
}

.contest-nav .contest-search .submit {
  display: none;
}

.contest-nav .contest-categories {
  float: right;
  text-transform: capitalize;
}

.contest-nav .item {
  margin: 0 5px;
  padding: 0 5px;
  display: inline-block;
}

.contest-nav .item:first-of-type {
  margin: 0 5px 0 15px;
}

.contest-nav .item a {
  color: #000;
  text-decoration: none;
}

.contest-nav .item a.active {
  font-weight: 700;
}

.contest-nav .item a:hover {
  text-decoration: underline;
}

.contest-profile-list {
  flex-wrap: wrap;
  display: flex;
}

.contest-profile-list .profile {
  text-align: left;
  box-sizing: border-box;
  background-color: #f5f7f9;
  border-radius: 15px;
  width: calc(20% - 20px);
  margin: 0 25px 20px 0;
  padding: 0 10px 15px;
  overflow-x: hidden;
}

.contest-profile-list .profile:hover {
  text-decoration: none;
}

.contest-profile-list .profile .name {
  text-transform: capitalize;
  font-size: 19px;
  font-weight: 700;
  line-height: 26px;
  display: block;
}

.contest-profile-list .profile .category {
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 19px;
  display: block;
  overflow: hidden;
}

.contest-profile-list .profile .info {
  color: #000;
  margin: 10px 0;
  font-weight: 400;
  display: block;
}

.contest-profile-list .profile .info em {
  margin-bottom: 5px;
  font-style: normal;
  line-height: 16px;
  text-decoration: none;
  display: block;
}

.contest-profile-list .profile .info .icon {
  float: left;
}

.contest-profile-list .profile .info .icon svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  display: block;
}

.contest-profile-list .profile .info .icon svg path {
  fill: #8295b1;
}

.contest-profile-list .profile .withdraw-text {
  color: #000;
  height: 37px;
  margin: 10px 0;
  font-weight: 400;
}

.contest-profile-list .profile img {
  width: auto;
  height: 190px;
  margin: 0 0 8px -10px;
  display: block;
}

.contest-profile-list .profile .vote {
  color: #fff;
  -webkit-appearance: none;
  appearance: none;
  background-color: #dd406f;
  border: 0;
  border-radius: 10px;
  outline: 0;
  padding: 0 18px;
  font-size: 15px;
  line-height: 40px;
}

.contest-profile-list .profile .play {
  background-color: #dd406f;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  margin-top: -64px;
  margin-left: 12.7%;
  display: block;
  position: absolute;
}

.contest-profile-list .profile .play:after {
  content: "";
  border-top: 12px solid rgba(0, 0, 0, 0);
  border-bottom: 12px solid rgba(0, 0, 0, 0);
  border-left: 18px solid #fff;
  border-radius: 0;
  width: 0;
  height: 0;
  margin: 11px 0 0 16px;
  display: block;
}

.contest-profile-list .profile:nth-child(5n) {
  margin-right: 0;
}

.contest-profile-list.top-list .profile {
  text-align: center;
  box-sizing: border-box;
  background-color: #f5f7f9;
  border-radius: 15px;
  width: calc(33.33% - 17px);
  margin: 0 25px 20px 0;
  padding: 5px 0 15px;
  overflow-x: hidden;
}

.contest-profile-list.top-list .profile:nth-child(5n) {
  margin-right: 25px;
}

.contest-profile-list.top-list .profile:nth-child(3n) {
  margin-right: 0;
}

.contest-profile-list.top-list .profile .vote {
  min-width: 50%;
}

.contest__nav {
  text-transform: uppercase;
  margin: 35px 0;
  font-size: 22px;
  line-height: 24px;
  display: flex;
}

.contest-nav__left {
  display: inherit;
  border-top: 2px solid #23292a;
  border-bottom: 2px solid #23292a;
  border-radius: 50% 10% 10% 50% / 4% 3% 3% 4%;
  justify-content: space-between;
  align-items: center;
  width: 790px;
  height: 44px;
  margin: 0 10px 0 0;
}

.contest-nav-left__categories, .contest-nav-left__sorting {
  font-family: Caveat;
}

.contest-nav-left__item {
  margin: 0 5px;
  padding: 0 5px;
  display: inline-block;
}

.contest-nav-left__item:first-of-type {
  margin: 0 5px 0 15px;
}

.contest-nav-left__item a {
  color: #000;
  text-decoration: none;
}

.contest-nav-left__item a.active {
  font-weight: 700;
}

.contest-nav-left__item a:hover {
  color: #ee7598;
}

.contest-nav__search-wrapper {
  background-image: url("/images/maminka-roku/svg/ramecek_251x44.svg");
  background-repeat: no-repeat;
  width: 251px;
  height: 44px;
}

.contest-nav__search {
  background-image: url("/images/maminka-roku/svg/lupa.svg");
  background-position: 216px;
  background-repeat: no-repeat;
  background-size: 18px;
  position: relative;
}

.contest-nav__search input {
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
}

.contest-nav-search__text {
  border: 0;
  width: 200px;
  height: 38px;
  margin: 2px 0 2px 4px;
  padding-left: 15px;
  font-size: 15px;
  line-height: 48px;
}

.contest-nav-search__submit {
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  width: 50px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
}

.contest__profile-list {
  flex-wrap: wrap;
  display: flex;
}

.contest__profile-list.top-list .contest__profile {
  text-align: center;
  box-sizing: border-box;
  background-color: #f5f7f9;
  border-radius: 15px;
  width: calc(33.33% - 17px);
  margin: 0 25px 20px 0;
  padding: 5px 0 15px;
  overflow-x: hidden;
}

.contest__profile-list.top-list .contest__profile:nth-child(5n) {
  margin-right: 25px;
}

.contest__profile-list.top-list .contest__profile:nth-child(3n) {
  margin-right: 0;
}

.contest__profile-list.top-list .contest__profile .vote {
  min-width: 50%;
}

.contest__profile {
  width: calc(20% - 20px);
  margin: 0 25px 70px 0;
  overflow-x: hidden;
}

.contest__profile:hover {
  text-decoration: none;
}

.contest__profile .name {
  color: #ee7598;
  text-transform: uppercase;
  font-family: Caveat;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  display: block;
}

.contest__profile .category {
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  margin: 5px 0 15px;
  font-weight: 700;
  line-height: 28px;
  display: block;
  overflow: hidden;
}

.contest__profile .withdraw-text {
  color: #000;
  height: 37px;
  margin: 10px 0;
  font-weight: 400;
}

.contest__profile .play {
  background-color: #dd406f;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  margin-top: -9.5%;
  margin-left: 6.5%;
  display: block;
  position: absolute;
}

.contest__profile .play:after {
  content: "";
  border-top: 12px solid rgba(0, 0, 0, 0);
  border-bottom: 12px solid rgba(0, 0, 0, 0);
  border-left: 18px solid #fff;
  border-radius: 0;
  width: 0;
  height: 0;
  margin: 11px 0 0 16px;
  display: block;
}

.contest__profile:nth-child(5n) {
  margin-right: 0;
}

.contest__profile--grey {
  filter: grayscale();
}

.contest-profile__image {
  width: 190px;
  height: 150px;
  margin-bottom: 5px;
  position: relative;
  overflow: hidden;
}

.contest-profile__image img {
  width: 100%;
  display: block;
}

.contest-profile__image .play {
  z-index: 10;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contest-profile__divider {
  border-top: 2px solid #000;
  border-radius: 70% 20% 0 0 / 10% 7% 0 50%;
  width: calc(100% - 36px);
  margin-left: 18px;
  padding-top: 15px;
}

.contest-profile-info__item {
  color: #000;
  justify-content: space-between;
  padding: 0 3px 0 2px;
  line-height: 28px;
  display: flex;
}

.contest-profile__vote {
  justify-content: center;
  margin: 15px auto 0;
  display: flex;
}

.contest-profile__vote .button-2021 {
  margin-bottom: 4px;
  line-height: 1;
}

.social-box {
  width: 100%;
}

.social-box .title {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: Caveat;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  display: flex;
}

.social-box .title img {
  width: 58px;
  height: 41px;
  margin-top: 24px;
}

.social-box .title .second-line {
  color: #ee7598;
}

.social-box .box {
  float: left;
  width: 33.3%;
  position: relative;
  overflow: hidden;
}

.social-box .box a {
  width: 100%;
  padding: 50%;
  display: block;
  position: relative;
}

.social-box .box img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.social-box .box svg {
  width: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.winner-overlay {
  z-index: 200;
  text-align: center;
  background-color: rgba(150, 150, 150, .8);
  padding-top: 200px;
  position: absolute;
  top: 0;
  bottom: -2000px;
  left: 0;
  right: 0;
}

.winner-overlay .winner-form {
  text-align: left;
  background-color: #ebeff3;
  border-radius: 15px;
  width: 630px;
  margin: 0 auto;
  padding: 25px;
}

.winner-overlay .winner-form .logo {
  width: 111px;
  height: 43px;
  margin: 0 auto;
  display: block;
}

.winner-overlay .winner-form .info {
  color: #000;
  text-align: center;
  padding: 0 15px 45px;
  font-size: 15px;
}

.winner-overlay .winner-form .close {
  text-align: center;
  width: auto;
  margin: 0 auto;
  display: block;
}

.winner-overlay .winner-form .close a {
  color: #000;
}

.winner-overlay .winner-form .close a.icon {
  width: 15px;
  height: 15px;
  margin: -2px 5px;
  text-decoration: none;
  display: inline-block;
}

.winner-overlay .winner-form h2 {
  text-align: center;
  color: #dd406f;
  font-size: 30px;
}

.winner-overlay .winner-form .field {
  max-width: 100%;
  padding-left: 150px;
  padding-right: 150px;
}

.winner-overlay .winner-form .field > span, .winner-overlay .winner-form .field input {
  max-width: 100%;
}

.winner-overlay .winner-form a {
  text-decoration: underline;
}

.winner-overlay .winner-form .agreement span {
  text-align: left;
  clear: left;
  margin-bottom: 5px;
  display: block;
}

.winner-overlay .winner-form .agreement span:after {
  content: "";
  clear: left;
  display: table;
}

.winner-overlay .winner-form .agreement em {
  float: left;
  margin-top: 5px;
}

.winner-overlay .winner-form .agreement label {
  float: left;
  max-width: calc(100% - 200px);
  margin-left: 15px;
  line-height: 20px;
}

.winner-overlay .winner-form .agreement .button {
  margin-top: 0;
  margin-bottom: 20px;
}

